<template>
  <div class="realName">
    <div v-show="addbankCardFlag">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户姓名" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            placeholder="请输入您的姓名"
            :readonly="!!identityData.name"
            name="userName"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="idCardType">
          <el-input  v-model="ruleForm.idCardType"  :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="证件号码" prop="cardId" class="marginB">
          <el-input
            v-model="ruleForm.cardId"
            placeholder="请输入您的证件号码"
            :readonly="!!identityData.idCard"
            name="cardId"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankCard">
          <el-input v-model="ruleForm.bankCard" placeholder="请输入您的银行卡号" ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" v-if="onlyShow.bankName">
          <el-input v-model="onlyShow.bankName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="开户行区域" v-if="onlyShow.province_city">
          <el-input v-model="onlyShow.province_city" :readonly="true"></el-input>
        </el-form-item>
        <div class="marginB"></div>
        <el-form-item label="预留手机号" prop="userphonenum">
          <el-input
            v-model="ruleForm.userphonenum"
            placeholder="请输入银行预留手机号"
            name="userphonenum"
            oninput="if(value.length > 11)value = value.slice(0, 11)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="验证码" prop="validatemsg">
          <el-col :span="15">
            <el-input v-model="ruleForm.validatemsg" placeholder="请输入验证码" name="validatemsg" oninput="if(value.length > 6)value = value.slice(0, 6)"></el-input>
          </el-col>
          <el-col :span="8">
            <button class="codeBtn" type="button" @click="getCode">{{ times }}</button>
          </el-col>
        </el-form-item> -->
        <button class="comfig" type="button" @click="submitForm('ruleForm')">确认</button>
      </el-form>
    </div>
    <div id="loading" v-if="isLoading">
    
    </div>
  </div>
</template>
<script>
import basic from '../utils/basic';
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserIsRealName,preFourFactorAuth,confirmFourFactorAuth} from "../services/api";
import $ from 'jquery'
let Base64 = require('js-base64').Base64;

export default {
  data() {
    return {
      addbankCardFlag: true,
      resultFlag: false,
      orderId: "", //订单号
      times: "获取验证码",
      canSend: true,
      codeFlag: false,
      userNature:1,
      bankcode: "",
      ruleForm: {
        idCardType: '身份证',
        userName: "",
        userNameFlag: false,
        cardId: "",
        cardIdFlag: false,
        bankCard: "",
        userphonenum: "",
        bankcode: ""
      },
      onlyShow: {},
      orderNo: "",
      tradeNo: "",
      isLoading: false,
      timestampStr: "",
      jwtToken: "",
      terminal: "",
      dfmaslkgh: "",
      userId: "",
      identityData: {
        name:"",
      }
    };
  },
  created: function() {
    var _this = this;
    //判断是否实名认证
      _this.queryUserIsRealName();

  },
  methods: {
    async bankInfo() {
      
    },
   async queryUserIsRealName() {//初始化接口
      const _this = this;
      const datas = await queryUserIsRealName({ });
          let encrypted = datas.data.encrypted;
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
          console.log(res);
          _this.ruleForm.userName = res.name || null;
          _this.ruleForm.cardId = res.idCard || null;
          _this.userNature = res.userNature;
          // if (!res.idCard && !_this.$route.query.notResidentIDCard) {
          //   _this.$router.replace('/');
          //  }
     
    },
    //  async bankInfo() {//获取银行卡信息
    //   //加密串
    //   console.log(basic.generateMixed(15));
    //   var timestamp = Date.parse(new Date()) + basic.generateMixed(15);
    //   var timestampStr = Base64.encode(timestamp);
    //   console.log(Base64.encode(timestamp));
    //   // RSA加密
    //   this.ruleForm.bankCard = (this.ruleForm.bankCard || '').replace(/\s/g, '');
    //   var aseKeyNew = encryptRSA();
    //   //第二个参数 requestData
    //   let reqdata = {
    //     cardNum: this.ruleForm.bankCard
    //   };
    //   //AES加密
    //   var data2 = encryptAES(reqdata);
    //   let sendData = {};
    //   sendData.msg = data2;
    //   sendData.encrypted = aseKeyNew;
    //  // const { status, data } = await getBankInfo({ chnl_code: 1, timestamp: timestampStr, data: sendData });
    //  const data={"data":{"msg":"2OTD8gJ9Oo6nofnrmRxxDVtTOfWvicVkeN9JyhN/+O7VlcrGn6jKDnmNwlVo9rfmm/H48AO5x0IcBmP7BmakU2snluoK4HRzC7Nu1NNs4E8tN7vMq7UD5QNVkmK3eWc2VLtnwfslURg0Z723rMnGJeQjtr2xJwFHLMnZ0kJl27opmKppDN3nCLsWdcSwNFe7kTApGko9ZaYXPmgrA43pf6XErPHxQLKEv0Gz2oHxP9YrsAb6D6Q3O+icls2dInKs+hmxNReoH6gwIhaLW032ask03hSOdCfIVrFEblHoWbseC5sAgvX7hcDPFkIF6Hgp5yHpjOf5E0oXyy2cQ8QcoOZ6oP6FmghfHrLCHEZjFFY=","encrypted":"UWAClNXYFGDt4VJGvd84FaUlbxMODSGs1BRgEXcNkFjqp6/guyQSg9KRiSKUZ6TiEGydFlNZwypN9s1JcXI4LqTtaVoeQD2BLKYf7PyXHp68qxKal7goarKywKhfSWl4ls/LyraeyzTiz86yLjVYEg5BVd+52yA1iY2T4fDd/1fM7KE90Su8p8gKg1wKMqJEu4j1RPTUZMFon6HcLYmeYFfdzfqs5A+HttST2QIxT6ZQffrNkfOJyxmXgkfLexAdqbL5iYsswf628P6nactL422vZdyVxdNWjiQb5YaBL4bTUrDf8blfcPgvJGAfe44zTzGX2RazId30wV5xEX16/g=="},"code":1,"msg":"成功"}
    //   console.log(data);

    //   if (+data.code === 1) {
    //     let encrypted = data.data.encrypted;
    //     let msg = data.data.msg;
    //     let aseKey = decryptRSA(encrypted); //RSA解密
    //     let res = JSON.parse(decryptAES(msg, aseKey)); //AES解密
    //     console.log(res);
    //     this.onlyShow = res;
    //     this.onlyShow.province_city = `${res.province} - ${res.city}`;
    //     this.ruleForm.bankCard = res.cardNum;
    //     this.ruleForm.bankcode = res.bankCode;
    //   }
    // },
    getQueryString(key) {
      var after = window.location.hash.split("?")[1];
      if (after) {
        var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
        var r = after.match(reg);
        if (r != null) {
          return decodeURIComponent(r[2]);
        } else {
          return null;
        }
      }
    },
    // getCode() {
    //   var _this = this;
    //   console.log("获取验证码-" + _this.canSend);
    //   var messageType = 1;
    //   var userName = _this.ruleForm.userName;
    //   var cardId = _this.ruleForm.cardId;
    //   var bankCard = _this.ruleForm.bankCard;
    //   var userphonenum = _this.ruleForm.userphonenum;
    //   if (userName == "") {
    //     _this.$toast('请输入用户姓名');
    //   } else if (cardId == "") {
    //     _this.$toast('请输入身份证号');
    //   } else if (bankCard == "") {
    //     _this.$toast('请输入银行卡号');
    //   } else if (userphonenum == "") {
    //     _this.$toast('请输入预留手机号');
    //   } else {
    //     if (_this.canSend == true) {
    //       _this.canSend = false;
    //       //加密串
    //       //实名绑卡发送验证码
    //       _this.preFourFactorAuth();
          
    //     }
    //   }
    // },
    // async preFourFactorAuth(){//获取验证码
    //   let _this=this;
    //   //加密串
    //     console.log(basic.generateMixed(15));
    //     var timestamp = Date.parse(new Date()) + basic.generateMixed(15);
    //     var timestampStr = Base64.encode(timestamp);
    //     console.log(Base64.encode(timestamp));
    //     // RSA加密
    //     var aseKeyNew = encryptRSA();
    //     //第二个参数 requestData
    //     let data = {
    //       userNature: _this.userNature, //用户类型：1-个人，2-企业
    //       name: _this.ruleForm.userName,
    //       idCardType: 1, //证件类型
    //       idCard: _this.ruleForm.cardId, //证件号码
    //       bankCard: _this.ruleForm.bankCard, //银行卡号
    //       phone: _this.ruleForm.userphonenum, //银行预留手机号
    //       sourceChannel: "YB"
    //     };
    //     //AES加密
    //     var data2 = encryptAES(data);
    //     let sendData = {};
    //     sendData.msg = data2;
    //     sendData.encrypted = aseKeyNew;
    //     sendData.byxToken = _this.$route.query.byxToken;

    //   const datas = await preFourFactorAuth({ chnl_code: 1, timestamp: timestampStr, data: sendData});
    //   if (datas.res_code === 1) {
    //       let encrypted = datas.data.encrypted;
    //       let data = datas.data.msg;
    //       let aseKey = decryptRSA(encrypted); //RSA解密
    //       let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
    //       console.log(res);
    //       _this.countdown(_this);//倒计时
    //       _this.codeFlag = true;
    //       _this.orderNo = res.orderNo;
    //       _this.tradeNo = res.tradeNo;
    //   } else {
    //     _this.$toast(datas.res_msg);
    //   }
    
    // },
    countdown(obj) {
      var myTime = 120;
      var timer = setInterval(function() {
        if (myTime == 1) {
          clearInterval(timer);
          obj.times = "重新获取";
          obj.canSend = true;
          obj.canSend = true;
        } else {
          myTime--;
          obj.times = myTime + "s";
        }
      }, 1000);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (this.codeFlag == false) {
          //   this.$toast("请点击获取验证码");
          // } else {
            this.confirmFourFactorAuth();//提交接口
         // }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  async confirmFourFactorAuth() {//提交接口
      const _this = this;
      //加密串
      console.log(basic.generateMixed(15));
      var timestamp = Date.parse(new Date()) + basic.generateMixed(15);
      var timestampStr = Base64.encode(timestamp);
      console.log(Base64.encode(timestamp));

      // RSA加密
      var aseKeyNew = encryptRSA();
      //第二个参数 requestData
      let data = {
        // userNature: _this.userNature, //用户类型：1-个人，2-企业
        // orderNo: _this.orderNo, //请求流水号
        // tradeNo: _this.tradeNo, //订单号
        idCardType:1,
        name:_this.ruleForm.userName,
        idCard:_this.ruleForm.cardId,
        bankCard: _this.ruleForm.bankCard, //银行卡号
        phone: _this.ruleForm.userphonenum, //银行预留手机号
        //authCode: _this.ruleForm.validatemsg //短信验证码
      };
      console.log(data);
      //AES加密
      var data2 = encryptAES(data);
      let sendData = {};
      sendData.msg = data2;
      sendData.encrypted = aseKeyNew;

      const datas = await confirmFourFactorAuth({chnl_code: 1, timestamp: timestampStr, data: sendData });
          if(datas.res_code==1){
            //跳转结果页
            _this.$router.replace({
              path: "resultBank",
              query: { }
            });
          } else {
            this.$toast(res_msg);
          }
    },
  },  
  computed: {
    rules() {
      let rule = {
        userName: [{ required: true, message: "请输入用户姓名", trigger: "blur" }],
        // idCardType: [{ required: true, message: "请选择证件类型", trigger: "change" }],
        test: [{ required: true, message: "请选择开户银行", trigger: "change" }],
        cardId: [{ required: true, message: "请输入证件号码", trigger: "blur" }],
        bankCard: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          { pattern: /^\d{15,19}$/, message: "请输入15~19位银行卡号", trigger: "blur" }
        ],
        userphonenum: [
          { required: true, message: "请输入预留手机号", trigger: "blur" },
          { pattern: /^1\d{10}$/, message: "请输入正确的手机号" }
        ],
        // validatemsg: [
        //   { required: true, message: "请输入验证码", trigger: "blur" },
        //   { pattern: /^\d{6}$/, message: "需输入6位验证码", trigger: "blur" }
        // ]
      };
      if (!this.$route.query.notResidentIDCard) {
        rule.cardId.push({ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确的身份证号" });
      }
      return rule;
    },
    cardType() {
      let opt = [
        {
          value: "1",
          label: "身份证",
          disabled: !!this.$route.query.notResidentIDCard
        },
        {
          value: "3",
          label: "中国护照"
        },
        {
          value: "6",
          label: "台湾来往大陆通行证"
        },
        {
          value: "7",
          label: "澳门来往大陆通行证"
        },
        {
          value: "8",
          label: "香港来往大陆通行证"
        }
      ];
      return opt;
    }
  }
};
</script>
<style scoped lang="scss">
.realName {
  .el-input__inner {
    padding: 0 8px;
    box-sizing: border-box;
  }
.el-select-dropdown__item{height: 3.4rem;}
.el-select-dropdown__wrap{max-height: 27.4rem;}
  .cardAdrrOpt {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
  }
}
</style>
